/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */

import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package

const prefix = 'urn';
const namespace = 'sfextslot';
const owner = 'publisher-pilet';

export const publisherDashboardExtensionSlots = {
	// this extension slot if for the publisher info widget to be displayed in the packages catalog
	publisherWidget: `${prefix}:${namespace}:${owner}:publisher-info` as const,
};
