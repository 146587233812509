import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { getApiBaseUrl } from '../../helpers/getApiBaseUrl';
import { GetPublisherParams, initializeSdk, PublisherInfo, PublisherSdk } from './sdk';

export class PublisherClient {
	private initializedSDK: PublisherSdk;

	public Initialize(sdk: PublisherSdk) {
		this.initializedSDK = sdk;
	}

	public listPublishers(): Promise<PublisherInfo[]> {
		return this.initializedSDK.listPublishers();
	}

	public getPublisher(params: GetPublisherParams): Promise<PublisherInfo> {
		return this.initializedSDK.getPublisher(params);
	}
}

export const publisherClient = new PublisherClient();

export function configurePublisherClient(app: PiletApi) {
	publisherClient.Initialize(
		initializeSdk({
			baseUrl: getApiBaseUrl(app),
		})
	);
}
