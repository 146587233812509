import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logError } from '../../../../logger';
import { GetPublisherParams, PublisherInfo, PublisherSdkConfiguration } from './types';

export interface PublisherSdk {
	listPublishers(): Promise<PublisherInfo[]>;
	getPublisher(params?: GetPublisherParams): Promise<PublisherInfo>;
}

export function initializeSdk(configuration: PublisherSdkConfiguration): PublisherSdk {
	const sharedConfig: AxiosRequestConfig = {
		baseURL: configuration.baseUrl,
		timeout: 60000,
		headers: {
			'x-bff-csrf': 'true',
		},
	};

	const publisherApi = axios.create(sharedConfig);

	return {
		listPublishers(): Promise<PublisherInfo[]> {
			const label = 'listPublishers';
			const url = '/';

			return apiProxy(async () => {
				return await publisherApi.get(url);
			}, label);
		},
		getPublisher(params: GetPublisherParams): Promise<PublisherInfo> {
			const label = 'getPublisher';
			const url = `/${encodeURIComponent(params.id)}`;

			return apiProxy(async () => {
				return await publisherApi.get(url);
			}, label);
		},
	};
}

async function apiProxy<T>(
	proxyFn: () => Promise<AxiosResponse<T>>,
	proxyLabel: string
): Promise<T> {
	let response: AxiosResponse<T, any>;
	try {
		response = await proxyFn();
	} catch (error) {
		logError(error, {
			customMessage: `Publishers API failure for ${proxyLabel}`,
		});
		throw error;
	}

	return response.data;
}
