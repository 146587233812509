import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PublisherWidgetExtensionProps } from './PublisherWidgetExtension';

const LazyPublisherWidgetExtension = React.lazy(
	() => import('./PublisherWidgetExtension')
);
const queryClient = new QueryClient();
export const AsyncLazyPublisherWidgetExtension: React.FC<
	PublisherWidgetExtensionProps
> = props => {
	return (
		<QueryClientProvider client={queryClient} contextSharing>
			<React.Suspense fallback={<></>}>
				<LazyPublisherWidgetExtension {...props} />
			</React.Suspense>
		</QueryClientProvider>
	);
};
