import { Api } from '@citrite/sf-api';
import { configurePublisherClient } from '@data/clients';
import { publisherDashboardExtensionSlots } from '@sdk/extensionTypes';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AsyncLazyPublisherWidgetExtension } from './Extensions';
import { setLogger } from './logger';

/**
 * Sets up the Publisher Pilet, registering the necessary
 * components and extensions needed for the Pilet.
 * @param app Pilet API
 */
export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());
	configureApiClients(app);
	registerExtensions(app);
	registerPages(app);
}

function configureApiClients(app: PiletApi) {
	configurePublisherClient(app);
	app.sf.registerSfApiConfigHandler(Api.configure);
}

function registerExtensions(app: PiletApi) {
	// Publisher Widget
	app.registerExtension(
		publisherDashboardExtensionSlots.publisherWidget,
		({ params }) => {
			return <AsyncLazyPublisherWidgetExtension {...params} />;
		}
	);
}

function registerPages(app: PiletApi) {
	// TODO: Add Pages as seems fit
}
